
<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			name: 'policies',
			form: {
				moneda: '',
				datosEn: '',
				comentariosSolicitudRiesgo: '',
				extenderPoliticaRiesgoGrupo: '',
				comentariosAdicionales: '',
				excluidaTestLaverage: 'NO',
				// datos: '',
			},
			formPoliticaLevel: 'all',
			formPolitica: {
				id: null,
				tipologia: '',
				ultimoAutorizado: 0,
				comprometido: 0,
				noComprometido: 0,
				limiteActual: 0,
				dispuesto: 0,
				propuesto: 0,
				padre: null,

			},

			politicas: [
				{
					id: 'total_riesgo_financiero_lp',
					tipologia: 'TOTAL Riesgo Financiero L/P',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: null,
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
					
				},
				{
					id: 'riesgo_financiero_lp_sin_garantias_reales',
					tipologia: 'Riesgo Financiero L/P Sin Garantías Reales',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: 'total_riesgo_financiero_lp',
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
				{
					id: 'riesgo_financiero_lp_con_garantias_reales',
					tipologia: 'Riesgo Financiero L/P Con Garantías Reales',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: 'total_riesgo_financiero_lp',
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
				{
					id: 'total_riesgo_financiero_cp',
					tipologia: 'TOTAL Riesgo Financiero C/P Y Comercial',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: null,
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
				{
					id: 'riesgo_comercial',
					tipologia: 'Riesgo Comercial',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: 'total_riesgo_financiero_cp',
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
				{
					id: 'riesgo_financiero_cp',
					tipologia: 'Riesgo Financiero CP',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: 'total_riesgo_financiero_cp',
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
				{
					id: 'riesgo_de_firma',
					tipologia: 'Riesgo De Firma',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: null,
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
				{
					id: 'riesgo_tesoreria',
					tipologia: 'Riesgo Tesorería',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: null,
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
				{
					id: 'total_riesgo_corporativo',
					tipologia: 'TOTAL RIESGO CORPORATIVO',
					ultimoAutorizado: 0,
					comprometido: 0,
					noComprometido: 0,
					limiteActual: 0,
					dispuesto: 0,
					propuesto: 0,
					padre: null,
					tipoAmortizacion: '',
					plazo: '',
					periodoDeGraciaMeses: '',
					periodoDeAmortizacion: '',
					garantiaDeAccionistas: '',
					moneda: '',
					seFormalizaCreditoAnteNotario: '',
					garantiasNoReales: '',
					digitalizacion: '',
					delegacion: '',
				},
			]
		}
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		deletePolicy(id) {
			let index = this.politicas.findIndex(p => p.id == id)

			this.politicas.splice(index, 1)
		},
		getParents() {
			return this.politicas.filter(p => !p.padre)
		},

		getChildren(parent) {
			return this.politicas.filter(p => p.padre == parent)
		},

		showEditPolicy(policy, formPoliticaLevel) {
			this.formPoliticaLevel = formPoliticaLevel
			this.$set(this, 'formPolitica', {...policy})
			this.showModal('#policyEditForm')
		},
		showInsertPolicy(policy) {
			this.$set(this, 'formPolitica', {
				id: null,
				tipologia: '',
				ultimoAutorizado: 0,
				comprometido: 0,
				noComprometido: 0,
				limiteActual: 0,
				dispuesto: 0,
				propuesto: 0,
				padre: policy.id,
				tipoAmortizacion: '',
				plazo: '',
				periodoDeGraciaMeses: '',
				periodoDeAmortizacion: '',
				garantiaDeAccionistas: '',
				moneda: '',
				seFormalizaCreditoAnteNotario: '',
				garantiasNoReales: '',
				digitalizacion: '',
				delegacion: '',
			})
			this.showModal('#policyInsertForm')
		},

		insertPolicy() {
			let data = {...this.formPolitica}
			let parentIndex = this.politicas.findIndex(p => p.id == data.padre)
			data.id = Date.now()
			this.politicas.splice(parentIndex, 0, data)
			$('#policyInsertForm').modal('hide')
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'El registro se actualizó satisfactoriamente'
			})
			this.clearFormPolicy()
			this.$nextTick(() => {
				this.showChildren(data.padre)
			})
			
		},

		editPolicy() {
			let data = {...this.formPolitica}

			let policy = this.politicas.find(p => p.id == data.id)
			if(policy) {
				policy.tipologia = data.tipologia
				policy.ultimoAutorizado = data.ultimoAutorizado
				policy.comprometido = data.comprometido
				policy.noComprometido = data.noComprometido
				policy.limiteActual = data.limiteActual
				policy.dispuesto = data.dispuesto
				policy.propuesto = data.propuesto
				policy.padre = data.padre
				policy.tipoAmortizacion = data.tipoAmortizacion
				policy.plazo = data.plazo
				policy.periodoDeGraciaMeses = data.periodoDeGraciaMeses
				policy.periodoDeAmortizacion = data.periodoDeAmortizacion
				policy.garantiaDeAccionistas = data.garantiaDeAccionistas
				policy.moneda = data.moneda
				policy.seFormalizaCreditoAnteNotario = data.seFormalizaCreditoAnteNotario
				policy.garantiasNoReales = data.garantiasNoReales
				policy.digitalizacion = data.digitalizacion
				policy.delegacion = data.delegacion
			}
			$('#policyEditForm').modal('hide')
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'El registro se actualizó satisfactoriamente'
			})
			this.clearFormPolicy()
		},
		clearFormPolicy() {
			this.$set(this, 'formPolitica', {
				id: null,
				tipologia: '',
				ultimoAutorizado: 0,
				comprometido: 0,
				noComprometido: 0,
				limiteActual: 0,
				dispuesto: 0,
				propuesto: 0,
				padre: null,
				tipoAmortizacion: '',
				plazo: '',
				periodoDeGraciaMeses: '',
				periodoDeAmortizacion: '',
				garantiaDeAccionistas: '',
				moneda: '',
				seFormalizaCreditoAnteNotario: '',
				garantiasNoReales: '',
				digitalizacion: '',
				delegacion: '',
			})
		},
		calcFill() {
			let fields = Object.keys(this.form)


			// if(this.form.clienteVisitado12Meses == 'NO') {
			// 	this.form.asistentes = ''
			// 	let index = fields.indexOf('asistentes')
			// 	fields.splice(index, 1)
			// 	this.form.comentariosSobreVisita = ''
			// 	index = fields.indexOf('comentariosSobreVisita')
			// 	fields.splice(index, 1)
			// 	this.form.fechaVisita = ''
			// 	index = fields.indexOf('fechaVisita')
			// 	fields.splice(index, 1)
			// }

			let points = 100 / fields.length
			let fill = 0
			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}
			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					moneda: '',
					datosEn: '',
					comentariosSolicitudRiesgo: '',
					extenderPoliticaRiesgoGrupo: '',
					comentariosAdicionales: '',
					excluidaTestLaverage: 'NO',
				})
				return 
			}
			this.$set(this, 'politicas', [...data.politicas])
			delete data.politicas
			this.$set(this, 'form', Object.assign({
					moneda: '',
					datosEn: '',
					comentariosSolicitudRiesgo: '',
					extenderPoliticaRiesgoGrupo: '',
					comentariosAdicionales: '',
					excluidaTestLaverage: 'NO',
				}, {...data}))
		},
		save() {
			let vm = this
			let payload =  {
				client:  this.client,
				form: this.name,
				fill: vm.calcFill(),
				data: Object.assign({...this.form}, {politicas: [...this.politicas]}),
			}
			vm._setDataChild(payload)
			vm.$emit('updateArce')
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'El registro se actualizó satisfactoriamente'
			})
			// this.hiddenSteps()
			// this.showSteps('stepsControls')

		},

	
		sumTotal(item, key) {
			if(!item.length) {
				return 0
			}
			let sum = item.reduce((a, c) => {
				let obj = {}
				obj[key] = Number(a[key]) + Number(c[key])
				return obj
			})

			return sum[key]
		},
	
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},

		toggleTableChildren(event) {
			let $target = event.target
			let $parent = $target.closest('tr')
			let $table = $parent.closest('table')
			let parentId = $parent.dataset.id
			let $children = $table.querySelectorAll(`[data-parent='${parentId}']`)
			$parent.classList.toggle('view')
			$children.forEach($child => {
				$child.classList.toggle('active')
			})
		},

		showChildren(parentId) {
			let $parent = document.querySelectorAll(`[data-id='${parentId}']`)
			let $children = document.querySelectorAll(`[data-parent='${parentId}']`)
			$parent.forEach($p => {
				$p.classList.add('active')
			})

			$children.forEach($child => {
				$child.classList.add('active')
			})
		},
		showedModal() {
			this.showSteps('stepsPolicies', 1)
		},
		closedModal() {
			this.hiddenSteps()
			this.showSteps('stepsControls')
			this.fillForm()
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		})
	},

	mounted() {
		$('#formPoliciesModal').on('shown.bs.modal', this.showedModal)
		$('#formPoliciesModal').on('hidden.bs.modal', this.closedModal)
	},

	beforeDestroy() {
		$('#formPoliciesModal').off('shown.bs.modal', this.showedModal)
		$('#formPoliciesModal').off('hidden.bs.modal', this.closedModal)

	}


}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="() => {showModal('#formPoliciesModal')}">
				Editar
			</button>
		</div>
		<div class="">
			<div class="s-collapse__header" data-toggle="collapse" data-target="#c1_p1" aria-expanded="true" aria-controls="c1_p1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Propuestas</span></h6>
				</div>
			</div>
			<div id="c1_p1" class="collapse show" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap mx-n2">
					<div class="col-4 p-2">
						<label class="d-block m-0" > 
							<p class="m-0">Moneda</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.moneda">
								
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Datos en</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.datosEn">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Fecha de aprobación</p>
							<input class="form-control form-control--disabled" disabled type="text">
						</label>
					</div>
				
				</div>
			</div>
			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_p2" aria-expanded="true" aria-controls="c1_p2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  Solitud de riesgo </span></h6>
				</div>
			</div>
			<div id="c1_p2" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex py-2 flex-wrap">
					<div class="col-12 p-2">
						<label class="d-block m-0">
							<p class="m-0">Comentarios</p>
							<textarea class="form-control form-control--disabled" disabled v-model="form.comentariosSolicitudRiesgo">
							</textarea>
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_p3" aria-expanded="true" aria-controls="c1_p3">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  Políticas</span></h6>
				</div>
			</div>
			<div id="c1_p3" class="collapse" aria-labelledby="headingOne">
				<div class="py-4">
					<table class="s-table w-100">
						<thead class="font-weight-bold text-center">
							<tr>
								<td></td>
								<td colspan="1">Límite Máximo</td>
								<td colspan="4">Fecha de exposición de riesgo</td>
								<td colspan="1">Nuevo Límite Máximo</td>
								<td></td>
							</tr>
							<tr>
								<th style="border-left: none; border-right: none">Tipología</th>
								<th style="border-left: none; border-right: none">Último Autorizado</th>
								<th style="border-left: none; border-right: none">Comprometido</th>
								<th style="border-left: none; border-right: none">No Comprometido</th>
								<th style="border-left: none; border-right: none">Límite Actual</th>
								<th style="border-left: none; border-right: none">Dispuesto</th>
								<th style="border-left: none; border-right: none">Propuesto</th>
								<th style="border-left: none; border-right: none"></th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(p,index) in getParents()">
								<tr :key="`policy_key_${index}_${p.id}`" :data-id="p.id">
									<td class="pl-3">
										<div class="position-relative">
											<button class="s-table-dropdown" @click="toggleTableChildren" v-if="getChildren(p.id).length">
												<SvgIcon name="chevronright"/>
											</button>
											{{p.tipologia}}
										</div>
									</td>
									<td>
										{{p.ultimoAutorizado}}
									</td>
									<td>
										{{p.comprometido}}
									</td>
									<td>
										{{p.noComprometido}}
									</td>
									<td>
										{{p.limiteActual}}
									</td>
									<td>
										{{p.dispuesto}}
									</td>
									<td>
										{{p.propuesto}}
									</td>
									<td class="text-center">
										<div class="dropdown mx-auto">
											<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												
											</button>
											<!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
												<a class="dropdown-item px-2 small disabled">Insertar</a>
												<a class="dropdown-item px-2 small" @click="showEditPolicy(p, 'primary')">Editar</a>
												<a class="dropdown-item px-2 small disabled">Eliminar</a>
											</div> -->
										</div>	
									</td>
								</tr>
								<template v-for="(c,index) in getChildren(p.id)">
									<tr class="child" :key="`policy_key_child_${index}_${p.id}_${c.id}`" :data-parent="p.id" :data-id="c.id">
										<td class="pl-4">
											<div class="position-relative">
												<button class="s-table-dropdown" @click="toggleTableChildren" v-if="getChildren(c.id).length">	<SvgIcon name="chevronright"/></button>
												{{c.tipologia}} 
											</div>
										</td>
										<td>
											{{c.ultimoAutorizado}}
										</td>
										<td>
											{{c.comprometido}}
										</td>
										<td>
											{{c.noComprometido}}
										</td>
										<td>
											{{c.limiteActual}}
										</td>
										<td>
											{{c.dispuesto}}
										</td>
										<td>
											{{c.propuesto}}
										</td>
										<td class="text-center">
											<div class="dropdown mx-auto">
												<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													
												</button>
												<!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
													<a class="dropdown-item px-2 small" @click="showInsertPolicy(c)">Insertar</a>
													<a class="dropdown-item px-2 small" @click="showEditPolicy(c, 'secondary')">Editar</a>
													<a class="dropdown-item px-2 small disabled">Eliminar</a>
												</div> -->
											</div>	
										</td>
									</tr>
									<tr class="child" :key="`policy_key_child_${index}_${c.id}_${sc.id}`" v-for="(sc,index) in getChildren(c.id)" :data-parent="c.id" :data-id="sc.id">
										<td class="pl-5">
											<div class="position-relative">
												{{sc.tipologia}} 
											</div>
										</td>
										<td>
										{{sc.ultimoAutorizado}}
									</td>
									<td>
										{{sc.comprometido}}
									</td>
									<td>
										{{sc.noComprometido}}
									</td>
									<td>
										{{sc.limiteActual}}
									</td>
									<td>
										{{sc.dispuesto}}
									</td>
									<td>
										{{sc.propuesto}}
									</td>
										<td class="text-center">
											<div class="dropdown mx-auto">
												<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													
												</button>
												<!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
													<a class="dropdown-item px-2 small disabled">Insertar</a>
													<a class="dropdown-item px-2 small" @click="showEditPolicy(sc, 'all')">Editar</a>
													<a class="dropdown-item px-2 small">Eliminar</a>
												</div> -->
											</div>	
										</td>
									</tr>
								</template>
							</template>
						</tbody>
					</table>
				</div>

				<div class="d-flex flex-wrap mx-n2">
					<div class="col-4 p-2">
						<label class="d-block">
							<p class="m-0"><span class="text-danger">*</span> Permitir extender la política de riesgos del grupo</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.extenderPoliticaRiesgoGrupo">
						</label>
					</div>
					<div class="col-12 p-2">
						<label class="d-block m-0">
							<p class="m-0">Comentarios adicionales que no modifican la política</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosAdicionales">
							</textarea>
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_p4" aria-expanded="true" aria-controls="c1_p4">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  Leveraged</span></h6>
				</div>
			</div>
			<div id="c1_p4" class="collapse" aria-labelledby="headingOne">
				<div class="py-3">
					<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_p4_1" aria-expanded="true" aria-controls="c1_p4_1">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  IDENTIFICACION</span></h6>
						</div>
					</div>
					<div id="c1_p4_1" class="collapse show" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Nombre Cliente</p>
									<input class="form-control form-control--disabled" disabled :value="client.name">
								</label>
							</div>
					
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Relación de grupo</p>
									<input class="form-control form-control--disabled" disabled value="Subcidiary">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Tipo de análisis</p>
									<input class="form-control form-control--disabled" disabled value="Individuales">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Rating</p>
									<input class="form-control form-control--disabled" disabled value="-B">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Indicadores de financial sponsor</p>
									<input class="form-control form-control--disabled" disabled value="SI">
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_m_p4_2" aria-expanded="true" aria-controls="c1_m_p4_2">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  EXCLUSIONES</span></h6>
						</div>
					</div>
					<div id="c1_p4_2" class="collapse show" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-4 p-2">
								<label class="d-block">
									<p class="m-0"> Entidad excluida del test Laveraged</p>
									<input class="form-control form-control--disabled" disabled v-model="form.excluidaTestLaverage">
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_m_p4_3" aria-expanded="true" aria-controls="c1_m_p4_3">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  MATERIALIDAD</span></h6>
						</div>
					</div>
					<div id="c1_m_p4_3" class="collapse show" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Límite propuesto en Arce</p>
									<input class="form-control form-control--disabled" disabled value="">
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_m_p4_4" aria-expanded="true" aria-controls="c1_m_p4_4">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  INFORMACIÓN ECONÓMICA Y FINANCIERA</span></h6>
						</div>
					</div>
					<div id="c1_m_p4_4" class="collapse show" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Fecha FFSS Leverage</p>
									<input class="form-control form-control--disabled" disabled value="">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Número de empleados</p>
									<input class="form-control form-control--disabled" disabled value="">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Ventas Millones</p>
									<input class="form-control form-control--disabled" disabled value="">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Balance Total Millones</p>
									<input class="form-control form-control--disabled" disabled value="">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Payment según definición del BCE</p>
									<input class="form-control form-control--disabled" disabled value="">
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formPoliciesModal" tabindex="-1" role="dialog" aria-labelledby="formPoliciesModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="">
						<div class="s-collapse__header" data-toggle="collapse" data-target="#c1_m_p1" aria-expanded="true" aria-controls="c1_m_p1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Propuestas</span></h6>
							</div>
						</div>
						<div id="c1_m_p1" class="collapse show" aria-labelledby="headingOne">
							<div class="d-flex flex-wrap mx-n2">
								<div class="col-4 p-2">
									<label class="d-block m-0" data-v-step="sp2">
										<p class="m-0">Moneda</p>
										<select class="form-control" type="text" v-model="form.moneda">
											<option value=""></option>
											<option value="Euros">Euros</option>
											<option value="Pesos Mexicanos">Pesos Mexicanos</option>
											<option value="Dólares">Dólares</option>
											<option value="Yuan">Yuan</option>
										</select>
									</label>
								</div>
								<div class="col-4 p-2">
									<label class="d-block m-0" data-v-step="sp3">
										<p class="m-0">Datos en</p>
										<select class="form-control" type="text" v-model="form.datosEn">
											<option value=""></option>
											<option value="Unidades">Unidades</option>
											<option value="Miles">Miles</option>
											<option value="Millones">Millones</option>
										</select>
									</label>
								</div>
								<div class="col-4 p-2">
									<label class="d-block m-0">
										<p class="m-0">Fecha de aprobación</p>
										<input class="form-control form-control--disabled" disabled type="text">
									</label>
								</div>
							
							</div>
						</div>
						<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_m_p2" aria-expanded="true" aria-controls="c1_m_p2">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  Solicitud de riesgo </span></h6>
							</div>
						</div>
						<div id="c1_m_p2" class="collapse" aria-labelledby="headingOne">
							<div class="d-flex py-2 flex-wrap">
								<div class="col-12 p-2">
									<label class="d-block m-0">
										<p class="m-0">Comentarios</p>
										<textarea class="form-control" v-model="form.comentariosSolicitudRiesgo">
										</textarea>
										<characters-available :max="1000" :text="form.comentariosSolicitudRiesgo"></characters-available>
									</label>
								</div>
								<!-- <label class="d-block">
									<p class="m-0"><span class="text-danger">*</span> El cliente ha sido visitado en los últimos 12 meses</p>
									<div class="s-tf">
										<label class="s-tf__item">
											<input type="radio" name="fe_cv12m" value="SI" v-model="form.clienteVisitado12Meses">
											<span class="s-tf__btn">SI</span>
										</label>
										<label class="s-tf__item" >
											<input type="radio" name="fe_cv12m" value="NO" v-model="form.clienteVisitado12Meses">
											<span class="s-tf__btn">NO</span>
										</label>
									</div>
								</label> -->
							</div>
						</div>
						<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_m_p3" aria-expanded="true" aria-controls="c1_m_p3">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  Políticas</span></h6>
							</div>
						</div>
						<div id="c1_m_p3" class="collapse" aria-labelledby="headingOne">
							<div class="py-4">
								<table class="s-table w-100">
									<thead class="font-weight-bold text-center">
										<tr>
											<td></td>
											<td colspan="1">Límite Máximo</td>
											<td colspan="4">Fecha de exposición de riesgo</td>
											<td colspan="1">Nuevo Límite Máximo</td>
											<td></td>
										</tr>
										<tr>
											<th style="border-left: none; border-right: none">Tipología</th>
											<th style="border-left: none; border-right: none">Último Autorizado</th>
											<th style="border-left: none; border-right: none">Comprometido</th>
											<th style="border-left: none; border-right: none">No Comprometido</th>
											<th style="border-left: none; border-right: none">Límite Actual</th>
											<th style="border-left: none; border-right: none">Dispuesto</th>
											<th style="border-left: none; border-right: none">Propuesto</th>
											<th style="border-left: none; border-right: none"></th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(p,index) in getParents()">
											<tr :key="`policy_key_${index}_${p.id}`" :data-id="p.id">
												<td class="pl-3">
													<div class="position-relative">
														<button class="s-table-dropdown" @click="toggleTableChildren" v-if="getChildren(p.id).length">
															<SvgIcon name="chevronright"/>
														</button>
														{{p.tipologia}}
													</div>
												</td>
												<td>
													{{p.ultimoAutorizado}}
												</td>
												<td>
													{{p.comprometido}}
												</td>
												<td>
													{{p.noComprometido}}
												</td>
												<td>
													{{p.limiteActual}}
												</td>
												<td>
													{{p.dispuesto}}
												</td>
												<td>
													{{p.propuesto}}
												</td>
												<td class="text-center">
													<div class="dropdown mx-auto">
														<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															
														</button>
														<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
															<a class="dropdown-item px-2 small disabled">Insertar</a>
															<a class="dropdown-item px-2 small" @click="showEditPolicy(p, 'primary')">Editar</a>
															<a class="dropdown-item px-2 small disabled">Eliminar</a>
														</div>
													</div>	
												</td>
											</tr>
											<template v-for="(c,index) in getChildren(p.id)">
												<tr class="child" :key="`policy_key_child_${index}_${p.id}_${c.id}`" :data-parent="p.id" :data-id="c.id">
													<td class="pl-4">
														<div class="position-relative">
															<button class="s-table-dropdown" @click="toggleTableChildren" v-if="getChildren(c.id).length">	<SvgIcon name="chevronright"/></button>
															{{c.tipologia}} 
														</div>
													</td>
													<td>
														{{c.ultimoAutorizado}}
													</td>
													<td>
														{{c.comprometido}}
													</td>
													<td>
														{{c.noComprometido}}
													</td>
													<td>
														{{c.limiteActual}}
													</td>
													<td>
														{{c.dispuesto}}
													</td>
													<td>
														{{c.propuesto}}
													</td>
													<td class="text-center">
														<div class="dropdown mx-auto">
															<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																
															</button>
															<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
																<a class="dropdown-item px-2 small" @click="showInsertPolicy(c)">Insertar</a>
																<a class="dropdown-item px-2 small" @click="showEditPolicy(c, 'secondary')">Editar</a>
																<a class="dropdown-item px-2 small disabled">Eliminar</a>
															</div>
														</div>	
													</td>
												</tr>
												<tr class="child" :key="`policy_key_child_${index}_${c.id}_${sc.id}`" v-for="(sc,index) in getChildren(c.id)" :data-parent="c.id" :data-id="sc.id">
													<td class="pl-5">
														<div class="position-relative">
															{{sc.tipologia}} 
														</div>
													</td>
													<td>
													{{sc.ultimoAutorizado}}
												</td>
												<td>
													{{sc.comprometido}}
												</td>
												<td>
													{{sc.noComprometido}}
												</td>
												<td>
													{{sc.limiteActual}}
												</td>
												<td>
													{{sc.dispuesto}}
												</td>
												<td>
													{{sc.propuesto}}
												</td>
													<td class="text-center">
														<div class="dropdown mx-auto">
															<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																
															</button>
															<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
																<a class="dropdown-item px-2 small disabled">Insertar</a>
																<a class="dropdown-item px-2 small" @click="showEditPolicy(sc, 'all')">Editar</a>
																<a class="dropdown-item px-2 small" @click="deletePolicy(sc.id)">Eliminar</a>
															</div>
														</div>	
													</td>
												</tr>
											</template>
										</template>
									</tbody>
								</table>
							</div>

							<div class="d-flex flex-wrap mx-n2">
								<div class="col-4 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> Permitir extender la política de riesgos del grupo</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="fe_eprg" value="SI" v-model="form.extenderPoliticaRiesgoGrupo">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item" >
												<input type="radio" name="fe_eprg" value="NO" v-model="form.extenderPoliticaRiesgoGrupo">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
								</div>
								<div class="col-12 p-2">
									<label class="d-block m-0">
										<p class="m-0">Comentarios adicionales que no modifican la política</p>
										<textarea class="form-control " type="text" v-model="form.comentariosAdicionales">
										</textarea>
										<characters-available :max="1000" :text="form.comentariosAdicionales"></characters-available>

									</label>
								</div>
							</div>
						</div>
						<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_m_p4" aria-expanded="true" aria-controls="c1_m_p4">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  Leveraged</span></h6>
							</div>
						</div>
						<div id="c1_m_p4" class="collapse" aria-labelledby="headingOne">
							<div class="py-3">
								<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_m_p4_1" aria-expanded="true" aria-controls="c1_m_p4_1">
									<div class="d-flex">
										<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  IDENTIFICACION</span></h6>
									</div>
								</div>
								<div id="c1_m_p4_1" class="collapse show" aria-labelledby="headingOne">
									<div class="d-flex flex-wrap mx-n2">
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Nombre Cliente</p>
												<input class="form-control form-control--disabled" disabled :value="client.name">
											</label>
										</div>
								
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Relación de grupo</p>
												<input class="form-control form-control--disabled" disabled value="Subcidiary">
											</label>
										</div>
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Tipo de análisis</p>
												<input class="form-control form-control--disabled" disabled value="Individuales">
											</label>
										</div>
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Rating</p>
												<input class="form-control form-control--disabled" disabled value="-B">
											</label>
										</div>
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Indicadores de financial sponsor</p>
												<input class="form-control form-control--disabled" disabled value="SI">
											</label>
										</div>
									</div>
								</div>
								<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_p4_2" aria-expanded="true" aria-controls="c1_p4_2">
									<div class="d-flex">
										<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  EXCLUSIONES</span></h6>
									</div>
								</div>
								<div id="c1_m_p4_2" class="collapse show" aria-labelledby="headingOne">
									<div class="d-flex flex-wrap mx-n2">
										<div class="col-4 p-2">
											<label class="d-block">
												<p class="m-0"> Entidad excluida del test Laveraged</p>
												<div class="s-tf">
													<label class="s-tf__item">
														<input type="radio" name="fe_etl" value="SI" v-model="form.excluidaTestLaverage">
														<span class="s-tf__btn">SI</span>
													</label>
													<label class="s-tf__item" >
														<input type="radio" name="fe_etl" value="NO" v-model="form.excluidaTestLaverage">
														<span class="s-tf__btn">NO</span>
													</label>
												</div>
											</label>
										</div>
									</div>
								</div>
								<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_m_p4_3" aria-expanded="true" aria-controls="c1_m_p4_3">
									<div class="d-flex">
										<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  MATERIALIDAD</span></h6>
									</div>
								</div>
								<div id="c1_m_p4_3" class="collapse show" aria-labelledby="headingOne">
									<div class="d-flex flex-wrap mx-n2">
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Límite propuesto en Arce</p>
												<input class="form-control form-control--disabled" disabled value="">
											</label>
										</div>
									</div>
								</div>
								<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#c1_m_p4_4" aria-expanded="true" aria-controls="c1_m_p4_4">
									<div class="d-flex">
										<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  INFORMACIÓN ECONÓMICA Y FINANCIERA</span></h6>
									</div>
								</div>
								<div id="c1_m_p4_4" class="collapse show" aria-labelledby="headingOne">
									<div class="d-flex flex-wrap mx-n2">
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Fecha FFSS Leverage</p>
												<input class="form-control form-control--disabled" disabled value="">
											</label>
										</div>
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Número de empleados</p>
												<input class="form-control form-control--disabled" disabled value="">
											</label>
										</div>
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Ventas Millones</p>
												<input class="form-control form-control--disabled" disabled value="">
											</label>
										</div>
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Balance Total Millones</p>
												<input class="form-control form-control--disabled" disabled value="">
											</label>
										</div>
										<div class="col-4 p-2">
											<label class="d-block m-0">
												<p class="m-0">Payment según definición del BCE</p>
												<input class="form-control form-control--disabled" disabled value="">
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>


		<!-- ++++++++++++++ -->
		<div class="modal s-modal fade" id="policyEditForm" tabindex="-1" role="dialog" aria-labelledby="policyEditFormLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="policyEditFormLabel">
								Editar
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<div class="d-flex flex-wrap mx-n2">
							<div v-if="formPoliticaLevel == 'all'" class="col-12 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Selecciona un producto</p>
									<select class="form-control small" v-model="formPolitica.tipologia">
										<option value="Crédito de habilitación o Avío">Crédito de habilitación o Avío</option>
										<option value="Crédito Refaccionario">Crédito Refaccionario</option>
										<option value="Crédito Simple">Crédito Simple</option>
										<option value="Crédito Líquido Flex">Crédito Líquido Flex</option>
										<option value="Selecciona un producto" disabled>Selecciona un producto</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Último Autorizado</p>
									<input class="form-control small" v-model="formPolitica.ultimoAutorizado" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Comprometido</p>
									<input class="form-control small" v-model="formPolitica.comprometido" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">No Comprometido</p>
									<input class="form-control small" v-model="formPolitica.noComprometido" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">L Actual</p>
									<input class="form-control small" v-model="formPolitica.limiteActual" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Dispuesto</p>
									<input class="form-control small" v-model="formPolitica.dispuesto" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Propuesto</p>
									<input class="form-control small" v-model="formPolitica.propuesto" type="number">
								</label>
							</div>
							<template v-if="formPoliticaLevel != 'primary'">
								<div class="col-6 p-2" v-if="formPoliticaLevel">
									<label class="d-block m-0">
										<p class="m-0 small">Tipo de Amortización</p>
										<select class="form-control small" v-model="formPolitica.tipoAmortizacion" >
											<option value="">--Ninguno--</option>
											<option value="Parciales">Parciales</option>
											<option value="Una sola al final">Una sola al final</option>
										</select>
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Plazo</p>
										<select class="form-control small" v-model="formPolitica.plazo" >
											<option value="">--Ninguno--</option>
											<option value="1 mes">1 mes</option>
											<option v-for="(n) of 35" :key="`meses_${n}`" :value="`${n + 1} meses`">{{`${n + 1} meses`}}</option>
										</select>
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Periodo de Gracia (meses)</p>
										<input class="form-control small" v-model="formPolitica.periodoDeGraciaMeses" type="text">
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Periodo de Amortización</p>
										<select class="form-control small" v-model="formPolitica.periodoDeAmortizacion" >
											<option value="">--Ninguno--</option>
											<option value="Anualmente">Anualmente</option>
											<option value="Mensual">Mensual</option>
											<option value="Otro">Otro</option>
										</select>
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Garamtía de Acciomistas / Grupo</p>
										<select class="form-control small" v-model="formPolitica.garantiaDeAccionistas" >
											<option value="">--Ninguno--</option>
											<option value="Garantía de los Accionistas">Garantía de los Accionistas</option>
											<option value="Garantía del Grupo">Garantía del Grupo</option>
										</select>
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Moneda</p>
										<input class="form-control small" v-model="formPolitica.moneda" type="text">
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Garantías No Reales</p>
										<select class="form-control small" v-model="formPolitica.seFormalizaCreditoAnteNotario" >
											<option value="">--Ninguno--</option>
											<option value="SI">SI</option>
											<option value="NO">NO</option>
										</select>
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Garantías No Reales</p>
										<select class="form-control small" v-model="formPolitica.garantiasNoReales" >
											<option value="">--Ninguno--</option>
											<option value="Aval / Obligación Solidaria">Aval / Obligación Solidaria</option>
											<option value="Gubernamenetal">Gubernamenetal</option>
											<option value="De una Institución Financiera">De una Institución Financiera</option>
											<option value="Stand By">Stand By</option>
											<option value="ECA">ECA</option>
											<option value="Comfort Letter">Comfort Letter</option>
											<option value="Cláusula de Permanencia">Cláusula de Permanencia</option>
										</select>
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Digitalization</p>
										<input class="form-control small" v-model="formPolitica.digitalizacion" type="text">
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Delegation</p>
										<input class="form-control small" v-model="formPolitica.delegacion" type="text">
									</label>
								</div>
							</template>
							<template v-else-if="formPolitica.id != 'total_riesgo_corporativo'">
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Delegation</p>
										<input class="form-control small" v-model="formPolitica.delegacion" type="text">
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Plazo</p>
										<select class="form-control small" v-model="formPolitica.plazo" >
											<option value="">--Ninguno--</option>
											<option value="1 mes">1 mes</option>
											<option v-for="(n) of 35" :key="`meses_${n}`" :value="`${n + 1} meses`">{{`${n + 1} meses`}}</option>
										</select>
									</label>
								</div>
								<div class="col-12 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Digitalization</p>
										<input class="form-control small" v-model="formPolitica.digitalizacion" type="text">
									</label>
								</div>
							</template>
							<template v-else>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Digitalization</p>
										<input class="form-control small" v-model="formPolitica.digitalizacion" type="text">
									</label>
								</div>
								<div class="col-6 p-2">
									<label class="d-block m-0">
										<p class="m-0 small">Delegation</p>
										<input class="form-control small" v-model="formPolitica.delegacion" type="text">
									</label>
								</div>
							</template>
						</div>
						<div class="bg-light p-2">
							<div class="text-center">
								<button class="s-btn s-btn--primary" @click="editPolicy">Guardar</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ++++++++++++++ -->
		<div class="modal s-modal fade" id="policyInsertForm" tabindex="-1" role="dialog" aria-labelledby="policyInsertFormLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="policyInsertFormLabel">
							Insertar Registro
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-12 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Selecciona un producto</p>
									<select class="form-control small" v-model="formPolitica.tipologia">
										<option value="Crédito de habilitación o Avío">Crédito de habilitación o Avío</option>
										<option value="Crédito Refaccionario">Crédito Refaccionario</option>
										<option value="Crédito Simple">Crédito Simple</option>
										<option value="Crédito Líquido Flex">Crédito Líquido Flex</option>
										<option value="Selecciona un producto" disabled>Selecciona un producto</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Último Autorizado</p>
									<input class="form-control small" v-model="formPolitica.ultimoAutorizado" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Comprometido</p>
									<input class="form-control small" v-model="formPolitica.comprometido" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">No Comprometido</p>
									<input class="form-control small" v-model="formPolitica.noComprometido" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">L Actual</p>
									<input class="form-control small" v-model="formPolitica.limiteActual" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Dispuesto</p>
									<input class="form-control small" v-model="formPolitica.dispuesto" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Propuesto</p>
									<input class="form-control small" v-model="formPolitica.propuesto" type="number">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Tipo de Amortización</p>
									<select class="form-control small" v-model="formPolitica.tipoAmortizacion" >
										<option value="">--Ninguno--</option>
										<option value="Parciales">Parciales</option>
										<option value="Una sola al final">Una sola al final</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Plazo</p>
									<select class="form-control small" v-model="formPolitica.plazo" >
										<option value="">--Ninguno--</option>
										<option value="1 mes">1 mes</option>
										<option v-for="(n) of 35" :key="`meses_${n}`" :value="`${n + 1} meses`">{{`${n + 1} meses`}}</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Periodo de Gracia (meses)</p>
									<input class="form-control small" v-model="formPolitica.periodoDeGraciaMeses" type="text">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Periodo de Amortización</p>
									<select class="form-control small" v-model="formPolitica.periodoDeAmortizacion" >
										<option value="">--Ninguno--</option>
										<option value="Anualmente">Anualmente</option>
										<option value="Mensual">Mensual</option>
										<option value="Otro">Otro</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Garamtía de Acciomistas / Grupo</p>
									<select class="form-control small" v-model="formPolitica.garantiaDeAccionistas" >
										<option value="">--Ninguno--</option>
										<option value="Garantía de los Accionistas">Garantía de los Accionistas</option>
										<option value="Garantía del Grupo">Garantía del Grupo</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Moneda</p>
									<input class="form-control small" v-model="formPolitica.moneda" type="text">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Garantías No Reales</p>
									<select class="form-control small" v-model="formPolitica.seFormalizaCreditoAnteNotario" >
										<option value="">--Ninguno--</option>
										<option value="SI">SI</option>
										<option value="NO">NO</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Garantías No Reales</p>
									<select class="form-control small" v-model="formPolitica.garantiasNoReales" >
										<option value="">--Ninguno--</option>
										<option value="Aval / Obligación Solidaria">Aval / Obligación Solidaria</option>
										<option value="Gubernamenetal">Gubernamenetal</option>
										<option value="De una Institución Financiera">De una Institución Financiera</option>
										<option value="Stand By">Stand By</option>
										<option value="ECA">ECA</option>
										<option value="Comfort Letter">Comfort Letter</option>
										<option value="Cláusula de Permanencia">Cláusula de Permanencia</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Digitalization</p>
									<input class="form-control small" v-model="formPolitica.digitalizacion" type="text">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0 small">Delegation</p>
									<input class="form-control small" v-model="formPolitica.delegacion" type="text">
								</label>
							</div>
						</div>
						<div class="bg-light p-2">
							<div class="text-center">
								<button class="s-btn s-btn--primary" @click="insertPolicy">Guardar</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.s-table{
	&-dropdown{
		position: absolute;
		right: 100%;
		background-color: transparent;
		width: 1rem;
		height: 1rem;
		padding: 0;
		text-align: center;
		line-height: 1rem;
		border: 0;
		outline: none;
		transition: 0.3s;
	}
	tr.child{
		display: none;
		&.active{
			display: table-row;
		}
	}
	tr.view{
		.s-table{
			&-dropdown{
				transform: rotate(90deg);
			}
		}
	}
}
</style>
